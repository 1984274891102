import { FC, useContext, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { motion } from 'framer-motion';
import theme from '@mui/theme';
import { searchMenuVariant } from '@mui/mui-animations';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HeaderContext } from '../../../Header.context';
// import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// import IconButton from '@mui/material/IconButton';
// import Close from '@mui/icons-material/Close';
import GridContainer from '@components/atoms/GridContainer/GridContainer';
import Row from '@components/atoms/CustomGrid/Row';
// import SearchLinks from '@components/molecules/SearchMenu/components/SearchLinks/SearchLinks';
// import SearchForm from '@organisms/Header/AltHeader/HeaderContent/shared/SearchMenu/SearchForm';
import { containerPadding } from '@utils/constants/constants';
import bg from '/public/assets/images/Marchio_big.svg';
import styles from './SearchMenu.module.scss';

const Box = dynamic(() => import('@mui/material/Box'));
const IconButton = dynamic(() => import('@mui/material/IconButton'));
const Close = dynamic(() => import('@mui/icons-material/Close'));
const SearchLinks = dynamic(() => import('@components/molecules/SearchMenu/components/SearchLinks/SearchLinks'));
const SearchForm = dynamic(() => import('@organisms/Header/AltHeader/HeaderContent/shared/SearchMenu/SearchForm'));

export const SearchMenu: FC = () => {
  const {
    isSearchMenuOpen,
    headerData: { searchConfig },
    onCloseSearchMenu,
  } = useContext(HeaderContext);

  const animation = useMemo(() => (isSearchMenuOpen ? 'open' : 'collapsed'), [isSearchMenuOpen]);

  const headerMediumBreakpoint = useMediaQuery('(min-width:1200px)');

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const links = useMemo(() => searchConfig?.[0]?.field_links, [searchConfig]);

  return (
    <motion.div
      className={styles.searchMenu}
      initial={animation}
      animate={animation}
      inherit={false}
      variants={searchMenuVariant}
      custom={{ height: isMobile ? 'auto' : '100vh', minHeight: isMobile ? '100vh' : 'auto' }}
    >
      {isMobile && (
        <Box className={styles.mobileClose}>
          <IconButton color="primary" sx={{ padding: 0 }} onClick={onCloseSearchMenu}>
            <Close fontSize="large" />
          </IconButton>
        </Box>
      )}

      <GridContainer
        sx={{
          display: 'flex',
          position: 'relative',
          backgroundImage: headerMediumBreakpoint
            ? `url(${bg.src}), linear-gradient(180deg, #E3E9EB -20.2%, #FFFFFF 40.14%)`
            : 'linear-gradient(180deg, #FFFFFF 0%, #E3E9EB 100%);',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left top',
          pointerEvents: isSearchMenuOpen ? 'auto' : 'none',
          height: '100%',
          alignItems: isMobile ? 'flex-start' : 'center',
          minHeight: isSearchMenuOpen && isMobile ? '100vh' : 'auto',
        }}
      >
        <Row>
          <Grid
            className={styles.searchForm}
            item
            xs={12}
            lg={7}
            sx={{ position: 'relative' }}
            pt={{ xs: 22, lg: 112 }}
            pb={{ xs: 50, lg: 112 }}
          >
            {isSearchMenuOpen && <SearchForm searchConfig={searchConfig} />}
          </Grid>

          <Grid item xs={12} lg={1} sx={{ justifyContent: 'center', display: 'flex' }}>
            <Divider orientation={isMobile ? 'horizontal' : 'vertical'} variant="middle" flexItem />
          </Grid>

          {links && (
            <Grid item xs={12} lg={4} pt={{ xs: 60, lg: 80 }} pb={{ xs: 60, lg: 80 }}>
              <SearchLinks links={links} />
            </Grid>
          )}
        </Row>
      </GridContainer>

      {!isMobile && (
        <IconButton
          color="primary"
          sx={{ position: 'absolute', top: 32, right: containerPadding }}
          onClick={onCloseSearchMenu}
        >
          <Close fontSize="large" />
        </IconButton>
      )}
    </motion.div>
  );
};

export default SearchMenu;
