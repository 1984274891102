import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';

import theme from '@mui/theme';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import Apps from '@mui/icons-material/Apps';
import Close from '@mui/icons-material/Close';
import East from '@mui/icons-material/East';
import Search from '@mui/icons-material/Search';

import CustomButton from '@atoms/CustomButton/CustomButton';
import GridContainer from '@atoms/GridContainer/GridContainer';
import LogoHeader from '@atoms/LogoHeader/LogoHeader';
import SearchMenu from '@organisms/Header/AltHeader/HeaderContent/shared/SearchMenu/SearchMenu';
import { HeaderContext } from '@organisms/Header/AltHeader/Header.context';
import { useColors } from '@hooks/useColors';
import styles from './HeaderMobileContent.module.scss';

const ChevronRight = dynamic(() => import('@mui/icons-material/ChevronRight'));
const ExpandLess = dynamic(() => import('@mui/icons-material/ExpandLess'));
const ExpandMore = dynamic(() => import('@mui/icons-material/ExpandMore'));
const Accordion = dynamic(() => import('@mui/material/Accordion'));
const AccordionDetails = dynamic(() => import('@mui/material/AccordionDetails'));
const AccordionSummary = dynamic(() => import('@mui/material/AccordionSummary'));
const ListItem = dynamic(() => import('@mui/material/ListItem'));
const RoundLabelIcon = dynamic(() => import('@atoms/RoundLabelIcon/RoundLabelIcon'));
const HeaderStockPrice = dynamic(() => import('@organisms/Header/components/HeaderStockPrice/HeaderStockPrice'), {
  ssr: false,
});
const EcosystemMenu = dynamic(
  () => import('@organisms/Header/AltHeader/HeaderContent/shared/EcosystemMenu/EcosystemMenu')
);

type Props = { show: boolean };

export const HeaderMobileContent = ({ show }: Props) => {
  const {
    headerData: { colorVariant, ecosystem, menus: menusData },
    langData,
    isMobileMenuOpen,
    onEcosystemButtonClick,
    onSearchButtonClick,
    onMobileMenuButtonClick,
    isEcosystemMenuOpen,
  } = useContext(HeaderContext);

  const { t } = useTranslation('common');
  const router = useRouter();
  const { locale, asPath } = router;
  const isMultiLang = Boolean(+process.env.NEXT_PUBLIC_MULTILANG);

  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(null);

  const drawerRef = useRef(null);
  const ecosystemRef = useRef(null);

  const { backgroundColor } = useColors(colorVariant);
  const logoColor = useMemo(() => (colorVariant === 'light' ? 'primary.main' : 'common.white'), [colorVariant]);
  const buttonColor = useMemo(
    () => (colorVariant === 'light' ? 'verticalMenuLink' : 'verticalMenuLinkOnDark'),
    [colorVariant]
  );

  const goNext = (i) => {
    setOpen(true);
    setIsVisible(i);
  };

  const goBack = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    goBack();
    onMobileMenuButtonClick();
  };

  useEffect(() => {
    if (!isEcosystemMenuOpen) return;

    setTimeout(() => {
      ecosystemRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
  }, [isEcosystemMenuOpen]);

  const getSubItems = (item) => {
    if (item?.tabs?.length) {
      return item.tabs.map((tab, i) => (
        <ListItem disablePadding key={`${tab.tabLabel}-${i}`}>
          <Accordion
            sx={{ width: '100%', px: 0 }}
            variant={colorVariant === 'light' ? 'accordionMenuLight' : 'accordionMenuDark'}
          >
            <AccordionSummary
              expandIcon={<ExpandMore fontSize="large" />}
              aria-controls="panel1a-content"
              sx={{ px: 0 }}
              id="panel1a-header"
            >
              <Typography px={0} fontSize={16} component="h3" fontWeight={500} lineHeight={'18.75px'}>
                {tab.tabLabel}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {tab.items.map((child) => (
                <CustomButton
                  key={`${child.label}-${child.url}`}
                  color={buttonColor}
                  component={child.url ? 'a' : 'button'}
                  external={child.isExternal}
                  target={child.target}
                  rel={child.rel}
                  sx={{ px: 0, fontWeight: 400 }}
                  tabIndex={0}
                  text={child.label}
                  variant="verticalMenuLink"
                  onClick={closeMenu}
                  {...(child.url && { href: child.url })}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </ListItem>
      ));
    }

    if (item?.verticalLinksList) {
      return item?.verticalLinksList.map((el, i) => (
        <ListItem disablePadding key={`${el.url}-${i}`}>
          {!el.childrenLinks ? (
            <CustomButton
              className="mainMenuItemVerticalLink withIcon"
              color={buttonColor}
              component={el.url ? 'a' : 'button'}
              external={el.isExternal}
              target={el.target}
              rel={el.rel}
              tabIndex={0}
              sx={{ p: 0 }}
              text={el.label}
              variant="verticalMenuLink"
              {...(el.url && { href: el.url })}
              onClick={closeMenu}
            />
          ) : (
            <Accordion
              sx={{ width: '100%', p: 0 }}
              variant={colorVariant === 'light' ? 'accordionMenuLight' : 'accordionMenuDark'}
            >
              <AccordionSummary
                expandIcon={<ExpandMore fontSize="large" sx={{ mx: 8 }} />}
                aria-controls="panel1a-content"
                sx={{
                  px: 0,
                  mr: -8,
                  height: 44,
                }}
                id="panel1a-header"
              >
                <CustomButton
                  className="mainMenuItemVerticalLink withIcon"
                  color={buttonColor}
                  component={el.url ? 'a' : 'button'}
                  external={el.isExternal}
                  target={el.target}
                  rel={el.rel}
                  tabIndex={0}
                  sx={{ p: 0 }}
                  text={el.label}
                  variant="verticalMenuLink"
                  {...(el.url && { href: el.url })}
                  onClick={el.url ? closeMenu : null}
                />
              </AccordionSummary>

              <AccordionDetails sx={{ margin: 0 }}>
                {el.childrenLinks.map((child) => (
                  <CustomButton
                    key={`${child.label}-${child.url}`}
                    color={buttonColor}
                    component={child.url ? 'a' : 'button'}
                    external={child.isExternal}
                    target={child.target}
                    rel={child.rel}
                    sx={{ px: 0, fontWeight: 400, textAlign: 'left' }}
                    tabIndex={0}
                    text={child.label}
                    variant="verticalMenuLink"
                    onClick={closeMenu}
                    {...(child.url && { href: child.url })}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        </ListItem>
      ));
    }

    return null;
  };

  return (
    <Box display={show ? 'block' : 'none'} className={styles.HeaderMobileContent}>
      <Drawer
        ref={drawerRef}
        variant="temporary"
        open={isMobileMenuOpen}
        onClose={onMobileMenuButtonClick}
        SlideProps={{
          direction: 'left',
        }}
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true,
        }}
        sx={{
          display: show ? 'block' : 'none',
          '& .MuiDrawer-paper': { boxSizing: 'border-box', backgroundColor, width: '100%' },
        }}
      >
        <Box sx={{ textAlign: 'center', backgroundColor, height: '100vh' }}>
          <SearchMenu />

          <GridContainer className={styles.header_drawer}>
            <Box className={styles.logo} display="flex" alignItems="center" justifyContent="space-between">
              <Search className={styles.btnSearch} color="primary" onClick={onSearchButtonClick} />

              <LogoHeader href="/" color={logoColor} />

              <IconButton color="primary" aria-label="open drawer" edge="end" onClick={onMobileMenuButtonClick}>
                <Close />
              </IconButton>
            </Box>
          </GridContainer>

          <GridContainer
            sx={{
              py: 20,
              display: { xs: 'block', md: 'none' },
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <HeaderStockPrice />

              {isMultiLang && (
                <>
                  <Box display="flex" gap={24}>
                    {langData?.map((el, i) => {
                      const isCurrentLocale = locale.toUpperCase() === el.lang.toUpperCase();
                      const MobileLangComponent = isCurrentLocale ? Typography : Link;

                      return (
                        <MobileLangComponent
                          key={`${el.path}-${i}`}
                          {...(!isCurrentLocale && {
                            className: `${styles.LanguagePicker} ${styles.LangLink}`,
                            href: asPath,
                            locale: el.lang.toLowerCase(),
                            prefetch: false,
                          })}
                          {...(isCurrentLocale && {
                            className: styles.LanguagePicker,
                            component: 'span',
                            color: 'grey.400',
                          })}
                        >
                          {el.lang.toUpperCase()}
                        </MobileLangComponent>
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>
          </GridContainer>

          {/* MENU BODY */}
          <GridContainer>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
                className={styles.mainMenuPrimary}
              >
                <List sx={{ pt: 0, pb: 24 }}>
                  {menusData?.map((item, i) => {
                    const hasChild = item?.verticalLinksList?.length > 0 || item.tabs?.length > 0;

                    return (
                      <Fragment key={`${item.title}-${i}`}>
                        <ListItem disablePadding>
                          <ListItemButton
                            sx={{ px: 0, py: 0 }}
                            component="div"
                            {...(hasChild ? { onClick: () => goNext(i) } : { onClick: onMobileMenuButtonClick })}
                          >
                            <Box display="flex" flexDirection="column" width="100%" position="relative">
                              <CustomButton
                                color={buttonColor}
                                component={!hasChild && item.url ? 'a' : 'button'}
                                tabIndex={0}
                                disableRipple
                                sx={{
                                  px: 0,
                                  py: theme.spacing(8),
                                  minHeight: theme.spacing(48),
                                  textTransform: 'uppercase',
                                  lineHeight: theme.spacing(19),
                                }}
                                text={item.title}
                                variant="verticalMenuLink"
                                {...(!hasChild && item.url && { href: item.url })}
                                {...(!!hasChild && {
                                  endIcon: (
                                    <ChevronRight sx={{ width: theme.spacing(32), height: theme.spacing(32) }} />
                                  ),
                                })}
                              />
                              {item?.iconName && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    left: 111,
                                    top: 4,
                                  }}
                                >
                                  <RoundLabelIcon iconName={item.iconName} variant="small" />
                                </Box>
                              )}
                            </Box>
                          </ListItemButton>
                        </ListItem>
                      </Fragment>
                    );
                  })}
                </List>
              </Box>

              <Divider sx={{ display: { xs: 'block', md: 'none' } }} />

              <Box
                ref={ecosystemRef}
                sx={{
                  pt: 24,
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <CustomButton
                  color={buttonColor}
                  tabIndex={0}
                  disableRipple
                  text={ecosystem.menuLabel}
                  variant="verticalMenuLink"
                  onClick={onEcosystemButtonClick}
                  startIcon={<Apps />}
                  endIcon={isEcosystemMenuOpen ? <ExpandLess /> : <ExpandMore />}
                />
              </Box>
            </Box>
          </GridContainer>

          <EcosystemMenu />
        </Box>
      </Drawer>

      {/* drawer nestato */}
      <Drawer
        ModalProps={{ keepMounted: false }}
        SlideProps={{ direction: 'left' }}
        open={open}
        onClose={closeMenu}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', backgroundColor, width: '100%' },
        }}
      >
        <GridContainer className={styles.header_drawer} sx={{ backgroundColor }}>
          <Box className={styles.logo} display="flex" alignItems="center" justifyContent="space-between">
            <Search
              className={styles.btnSearch}
              color="primary"
              onClick={() => {
                goBack();
                onSearchButtonClick();
              }}
            />
            <LogoHeader href="/" color={logoColor} />

            <IconButton color="primary" aria-label="open drawer" edge="end" onClick={closeMenu}>
              <Close />
            </IconButton>
          </Box>
        </GridContainer>

        <Box sx={{ backgroundColor }}>
          {menusData.map((item, index) => {
            return (
              isVisible === index && (
                <Fragment key={`sub-${item.title}-${index}`}>
                  <GridContainer sx={{ py: 20 }}>
                    <Button variant="link" sx={{ textTransform: 'unset' }} color="link" onClick={goBack}>
                      <Typography variant="caption" color="primary" mb={20} display="block">
                        {t('header.goBack')}
                      </Typography>
                    </Button>

                    <ListItem disablePadding>
                      <CustomButton
                        variant="link"
                        component="a"
                        disableRipple
                        sx={{
                          justifyContent: 'flex-start',
                          px: 0,
                          fontWeight: 300,
                          gap: theme.spacing(16),
                          fontSize: theme.spacing(32),
                          lineHeight: theme.spacing(40),
                          textTransform: 'none',
                          svg: { height: theme.spacing(32), width: theme.spacing(32) },
                        }}
                        href={item.url}
                        onClick={closeMenu}
                        color={buttonColor}
                        text={item.title}
                        endIcon={<East fontSize="large" />}
                      ></CustomButton>
                    </ListItem>
                  </GridContainer>

                  <GridContainer>
                    <List>{getSubItems(item)}</List>
                  </GridContainer>
                </Fragment>
              )
            );
          })}
        </Box>
      </Drawer>
    </Box>
  );
};

export default HeaderMobileContent;
